<route>
{
  "meta": {
    "permission": [
      "inventory.view_movement"
    ]
  }
}
</route>

<template>
  <i-table
    :title="$tc('discrepancy', 2)"
    :headers="headers"
    app="purchase.purchaseorderproduct"
    api="purchase.pending"
    dontRemove
    dontCreate
    dontEdit
  >
  </i-table>
</template>
<!--getPurchasePending-->
<script>
export default {
  computed: {
    headers() {
      return [
        {
          text: this.$tc('date', 2),
          value: 'received_date'
        },
        {
          text: this.$tc('order', 1),
          value: 'name'
        },
        {
          text: this.$tc('product', 1),
          value: 'own_prod'
        },
        {
          text: this.$t('quantity'),
          value: 'quantity'
        },
        {
          text: this.$t('received_quantity'),
          value: 'received_quantity'
        },
        {
          text: this.$t('pending_quantity'),
          value: 'pending_quantity'
        },
        {
          text: this.$t('received_date'),
          value: 'received_date'
        }
      ]
    }
  }
}
</script>
